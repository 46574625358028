import React, { useContext, useEffect, useMemo, useRef, useState } from "react"
import { graphql, navigate, useStaticQuery } from "gatsby"
import { useMergePrismicPreviewData, withPrismicPreview } from "gatsby-plugin-prismic-previews"
import { useLocation } from "@reach/router"
import { LayoutContext } from "../components/layout"
import { Carousel } from "../components/Carousel"
import { MobileLegal } from "../components/MobileLegal"
import { usePrevious } from "../hooks/usePrevious"
import { slideSliceHasContent } from "../utilities/slideSliceHasContent"
import { Helmet } from "react-helmet"
import { resizeImage } from "../utilities/resizeImage"
import slugify from "slugify"
import fastdom from "fastdom"

const WorkQuery = graphql`
  query WorkQuery {
    allPrismicCategory {
      nodes {
        _previewable
        data {
          name
        }
        id
      }
    }
    prismicSettings {
      _previewable
      data {
        title
        work {
          carousel {
            document {
              ... on PrismicCarousel {
                _previewable
                data {
                  autoplay
                  body {
                    ... on PrismicCarouselDataBodySlide {
                      id
                      primary {
                        image {
                          alt
                          gatsbyImageData(placeholder: BLURRED)
                          dimensions {
                            height
                            width
                          }
                        }
                      }
                      slice_type
                    }
                    ... on PrismicCarouselDataBodyVideoSlide {
                      id
                      primary {
                        embed {
                          id
                          provider_name
                          provider_url
                          type
                          title
                          width
                          height
                          embed_url
                          video_id
                          duration
                          account_type
                          author_name
                          author_url
                          description
                          html
                          is_plus
                          prismicId
                          thumbnail_height
                          thumbnail_url
                          thumbnail_url_with_play_button
                          thumbnail_width
                          uri
                          version
                        }
                      }
                      slice_type
                    }
                  }
                  categories {
                    category {
                      document {
                        ... on PrismicCategory {
                          id
                          data {
                            name
                          }
                        }
                      }
                    }
                  }
                  client {
                    document {
                      ... on PrismicClient {
                        id
                        data {
                          name
                          description {
                            richText
                          }
                          url {
                            url
                          }
                        }
                      }
                    }
                  }
                  position
                  size
                  speed
                }
              }
            }
            id
          }
        }
      }
    }
  }
`

const arrayContentsAndIndicesEqual = (a, b) => a.length === b.length && a.every((e, i) => a[i] === b[i] && a.indexOf(e) === b.indexOf(e))

const carouselNodeHasContent = (node) => 
  node?.carousel?.document?.data?.body?.some(slice => slideSliceHasContent(slice))

const generateCategoryNames = (categories: Array<unknown>): string[] => {
  return categories?.map((node: any) => node?.category?.document?.data?.name)?.filter(c => c)
}

const generateIntersectionStateSetterCallback = (stateRef, stateSetter, destructive = true) => {
  return (entries, observer) => {
    const newState = stateRef.current.slice()
    let stateChanged = false

    entries.forEach(entry => {
      const index = parseInt(entry.target.getAttribute(`data-index`) ?? ``, 10)
      if(typeof index === `undefined`) return

      const indexOfEntryInState = newState.indexOf(index)

      if(entry.isIntersecting) {
        if(indexOfEntryInState === -1) {
          newState.push(index)
          stateChanged = true
        }
      } else {
        if(destructive && indexOfEntryInState > -1) {
          newState.splice(indexOfEntryInState, 1)
          stateChanged = true
        }
      }
    })

    if(stateChanged) {
      fastdom.mutate(() => {
        stateSetter(newState)
      })
    }
  }
}

const Work = (props: any) => {
  const staticData = useStaticQuery(WorkQuery),
    { data: { prismicSettings } } = useMergePrismicPreviewData(staticData),
    { state } = useLocation(),
    stateHasClientRef = useRef(state?.client),
    { filters, reduceMotion, transitioning, setClient, setClientIndex } = useContext(LayoutContext),
    carousels = useMemo(() => prismicSettings?.data?.work?.filter(node => carouselNodeHasContent(node)), [prismicSettings?.data?.work]),
    [nearScreenState, _setNearScreenState] = useState<number[]>([]),
    nearScreenStateRef = useRef(nearScreenState),
    setNearScreenState = (newNearScreenState) => {
      nearScreenStateRef.current = newNearScreenState
      _setNearScreenState(newNearScreenState)
    },
    [onScreenState, _setOnScreenState] = useState<number[]>([]),
    onScreenStateRef = useRef(onScreenState),
    setOnScreenState = (newOnScreenState) => {
      onScreenStateRef.current = newOnScreenState
      _setOnScreenState(newOnScreenState)
    },
    filteredCarousels = useMemo(() => filters.length > 0
      ? carousels.filter(node => {
        const carouselCategories = generateCategoryNames(node?.carousel?.document?.data?.categories ?? [])
        return carouselCategories.some((category: string) => filters?.includes(category))
      }) : carousels, [carousels, filters]),
    filteredCarouselsRef = useRef(filteredCarousels),
    filteredCarouselIds = filteredCarousels.map(node => node.carousel.id),
    filteredCarouselComponents = useMemo(() => carousels?.map((node: any, i: number, a: any[]) => {
      const caption = !(node?.carousel?.document?.data?.size === `Large` && a[i + 1]?.carousel?.document?.data?.size === `Large`),
        filteredIndex = filteredCarouselIds.indexOf(node.carousel.id)
      return (
        <Carousel
          caption={caption}
          // centered={clientIndex === i}
          data={node.carousel.document.data}
          index={i}
          filteredIndex={filteredIndex}
          key={node.carousel.id}
          length={filteredCarousels.length}
          filtered={filteredIndex > -1}
          nearScreen={nearScreenState.indexOf(i) > -1}
          onScreen={onScreenState.indexOf(i) > -1}
        />
      )
    }), [filteredCarousels, nearScreenState, onScreenState]),
    previousFilteredCarouselIds = usePrevious(filteredCarouselIds),
    carouselSharingImageTags = useMemo(() => carousels
    .map(node => node?.carousel?.document?.data?.body[0]?.primary?.image?.gatsbyImageData && resizeImage(node?.carousel?.document?.data?.body[0]?.primary?.image?.gatsbyImageData))
    .filter(image => image)
    .flatMap(image => image.src && image.width && image.height && [
      <meta key={`${image.src}-twitter`} name="twitter:image" content={image.src} />,
      <meta key={`${image.src}-og`} property="og:image" content={image.src} />,
      <meta key={`${image.src}-og-width`} property="og:image:width" content={`${image.width}`} />,
      <meta key={`${image.src}-og-height`} property="og:image:height" content={`${image.height}`} />,
    ]).slice(0, 4 * 3), [carousels])

  // Synchronize navigation client state with ref to allow updated access in intersection observer
  useEffect(() => {
    stateHasClientRef.current = state?.client
  }, [state])

  // When carousel list changes, scroll to top
  useEffect(() => {

    if(transitioning) return
    if(!previousFilteredCarouselIds) return
    if(arrayContentsAndIndicesEqual(previousFilteredCarouselIds, filteredCarouselIds)) return

    // console.log(`scroll to top due to carousel list change`)
    document.querySelector(`[data-scroll-root="/work"]`)?.scrollTo({
      top: 0,
      left: 0,
      behavior: `auto`,
    })

    // Update client
    setClientIndex(0)
    setClient(filteredCarousels[0]?.carousel?.document?.data?.client?.document?.data)
  }, [filteredCarouselIds, previousFilteredCarouselIds, transitioning])

  useEffect(() => {
    filteredCarouselsRef.current = filteredCarousels
  }, [filteredCarousels])

  let clientElement, clientIndex, scrollingElement, clientOffsetTop, clientOffsetHeight, scroll = 0

  const nearScreenObserverRef = useRef<IntersectionObserver>(),
    nearScreenCallback = generateIntersectionStateSetterCallback(nearScreenStateRef, setNearScreenState, false),
    onScreenObserverRef = useRef<IntersectionObserver>(),
    onScreenCallback = generateIntersectionStateSetterCallback(onScreenStateRef, setOnScreenState),
    centeredObserverRef = useRef<IntersectionObserver>()

  useEffect(() => {
    const root = document.querySelector(`[data-scroll-root="/work"]`),
      // Select carousels using [data-client] because this excludes top/bottom padding
      targets = Array.from(document.querySelectorAll(`[data-client]`))

    nearScreenObserverRef.current = new IntersectionObserver(
      nearScreenCallback, {
      root,
      rootMargin: `100%`, // near screen
      threshold: undefined,
    })
    
    onScreenObserverRef.current = new IntersectionObserver(
      onScreenCallback, {
      root,
      rootMargin: `0%`, // on screen
      threshold: undefined,
    })

    centeredObserverRef.current = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        const index = parseInt(entry.target.getAttribute(`data-index`) ?? ``, 10),
          filteredIndex = parseInt(entry.target.getAttribute(`data-filtered-index`) ?? ``, 10)

        if(typeof index === `undefined` || !entry.isIntersecting) return

        const clientName = entry.target.getAttribute(`data-formal`) ?? ``

        // Additional checks when navigating directly to a client carousel
        if(stateHasClientRef.current) {
          const clientNameSlug = slugify(clientName.toLowerCase()),
            clientNameSlugMatchesState = stateHasClientRef.current && stateHasClientRef.current === clientNameSlug

          // We have arrived, clear state so we can load rest of page
          if(clientNameSlugMatchesState) navigate(`/work`, { state: {}, replace: true })
          // Otherwise, break prematurely to prevent incorrect client state setting: does not match navigation state
          else return
        }

        const clientDocument = filteredCarouselsRef.current[filteredIndex]?.carousel?.document?.data?.client?.document?.data ?? { name: `` }
    
        setClientIndex(filteredIndex)
        setClient(clientDocument)
      })
    }, {
      root,
      rootMargin: `-50%`, // centered
      threshold: undefined,
    })

    targets.forEach(target => {
      nearScreenObserverRef.current?.observe(target)
      onScreenObserverRef.current?.observe(target)
      centeredObserverRef.current?.observe(target)
    })

    return () => {
      nearScreenObserverRef.current?.disconnect()
      onScreenObserverRef.current?.disconnect()
      centeredObserverRef.current?.disconnect()
    }
  }, [])

  useEffect(() => {
    if(!((transitioning || reduceMotion) && state?.client && typeof document !== `undefined`)) return

    clientElement = document.querySelector(`[data-client="${CSS.escape(state.client)}"]`)
    scrollingElement = document.querySelector(`[data-scroll-root="/work"]`)
    clientOffsetTop = clientElement?.offsetTop ?? 0
    clientOffsetHeight = clientElement?.offsetHeight ?? 0
    scroll = clientOffsetTop - ((document.documentElement.clientHeight - clientOffsetHeight) / 2)

    if(clientElement) {
      clientIndex = clientElement?.getAttribute(`data-index`)
      if(typeof clientIndex === `string`) setClientIndex(parseInt(clientIndex, 10))
    }

    if(scrollingElement) {
      scrollingElement.scrollTop = scroll
    }
  }, [reduceMotion, state, transitioning])

  return (
    <>
      <Helmet>
        {/* Title */}
        <title>Work</title>
        <meta property="og:title" content={`Work | ${prismicSettings?.data?.title}`} />
        <meta name="twitter:title" content={`Work | ${prismicSettings?.data?.title}`} />
        {/* Image */}
        {carouselSharingImageTags}
      </Helmet>
      <div>
        {/* Leave space for menu on mobile */}
        <div className="sm:hidden m-2.5 p-2.5 text-base">&nbsp;</div>
        {/* List carousels in order provided by settings */}
        {filteredCarouselComponents}
        <MobileLegal />
      </div>
    </>
  )
}

export default withPrismicPreview(Work)
