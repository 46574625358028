import React, { useContext, useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { useMergePrismicPreviewData } from "gatsby-plugin-prismic-previews"
import { useInView } from "react-intersection-observer"
import { LayoutContext } from "./layout"

const MobileLegalQuery = graphql`
  query MobileLegalQuery {
    prismicSettings {
      _previewable
      data {
        formal_name
      }
    }
  }
`

const MobileLegal = () => {
  const staticData = useStaticQuery(MobileLegalQuery),
  { data: { prismicSettings } } = useMergePrismicPreviewData(staticData),
    { data: { formal_name } } = prismicSettings,
    year = new Date().getFullYear(),
    { setMobileFooterVisible } = useContext(LayoutContext),
    { ref: inViewRef, inView } = useInView()

  useEffect(() => {
    setMobileFooterVisible(inView)
  }, [inView])

  return (
    <div
      className="sm:hidden mt-[calc(180px+36px)] p-10 bg-black text-white"
      data-theme="dark"
      data-hide-meta="true"
      ref={inViewRef}
    >
      <p className="text-white text-opacity-40">
        © {year} {formal_name ?? `Javas Lehn Studio LLC`}. All rights reserved.
      </p>
      <p className="text-white text-opacity-40">No part of this website may be reproduced, distributed or transmitted in any form or by any means, without prior written permission.</p>
    </div>
  )
}

export default MobileLegal
export { MobileLegal }